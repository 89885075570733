<div class="rgtContent">
  <h2 class="heading-02">Business Proposal</h2>
  <div class="rgt-prag">
    <h1><strong>Initial Checklist</strong></h1>
    <p>
      Franchise would be required to provide 150 - 200 sq. ft. carpet area in
      strong retail area on ground floor with frontage of 5 - 8 ft.
    </p>
    <p>Warehouse required more than 300 sq. ft. carpet area.</p>
    <p>
      Storage space for stock would be the onus of the franchise and additional
      expense, if any, for the same would be borne by the franchise.
    </p>
    <p>
      Premium Electronics Store will be personally managed by the Franchise.
      Fonttech will hire a store manager for the managing the store. Franchise
      would also provide one dedicated resource in form of helper for upkeep and
      maintain hygiene factor of the store.
    </p>
    <!-- <p>
      <strong>Investment Estimate</strong>
    </p> -->
    <!-- <p>The initial set-up investment stands at an estimate of Rs. 5.50 Lakhs. The breakup is as follows:</p> -->
    <!-- <div class="table-view">
      <table class="table" >
        <tbody>
          <tr>
            <th>Particulars</th>
            <th>Approx. Cost (INR)</th>
          </tr>
          <tr>
            <td>
              Civil Work (False Ceiling, Nameplate Digital Light Board,  Glass Facade, Electric Fittings,
              Air Conditioner, Computer, Printer etc.)
            </td>
            <td>
              Rs. 2 Lakh <br />(varies with the store size, layout and variance in
              standardized rates)
            </td>
          </tr>
          <tr>
            <td>Display Stock</td>
            <td>
              Rs. 1.5 Lakh <br />(additional One Time Support of 15% off on MRP per SKU)
            </td>
          </tr>
          <tr>
            <td>On Hand Saleable Stock</td>
            <td>Rs. 1.5 Lakh</td>
          </tr>
          <tr>
            <td>Security Deposit</td>
            <td>Rs. 50 Thousand <br>(Refundable, with 6% Rate of Interest p.a.)</td>
          </tr>
        </tbody>
      </table>
    </div> -->

    <!-- <p>
      Brand Advertisement Cost will be Managed by Fonttech along with that Electricity Bill will be Managed by Fonttech. Staff hiring & training also given by Fonttech.
      Store interiors, layout and branding is done as per the guidelines laid down by Fonttech through its appointed designing agency. 
      The cost of interior fixtures and branding will be borne solely by Fonttech,
       and therefore will be Fonttech’s property. 
       The Franchise is expected to maintain the high quality of the interior throughout the operation of the Smart Shop. 
       Any subsequent maintenance cost, handling damages and wear-and-tear, will be borne by Franchise to conform to the quality standard as defined by Fonttech.
    </p> -->

    <!-- <div>
      <h2>Fonttech Franchise Information</h2>
      <ul>
        <li><strong>Brand Advertisement Cost:</strong> Managed by Fonttech.</li>
        <li><strong>Electricity Bill:</strong> Managed by Fonttech.</li>
        <li><strong>Staff Hiring & Training:</strong> Provided by Fonttech.</li>
        <li><strong>Interior Fixtures and Branding Costs:</strong> Borne solely by Fonttech and will remain Fonttech’s property.</li>
      </ul>
      <p>
        The Franchise is expected to maintain the high quality of the interior throughout the operation of the Smart Shop. Any subsequent maintenance cost, handling damages, and wear-and-tear will be borne by the Franchise to conform to the quality standards as defined by Fonttech.
      </p>
    </div> -->

    <div>
      <h1><strong>Fonttech Franchise Information</strong></h1>

      <section>
        <h2><strong>Costs Covered by Fonttech</strong></h2>
        <ul class="custom-list">
          <li>
            <strong>Brand Advertisement:</strong> Managed by Fonttech.
          </li>
          <li>
            <strong>Marketing Activity:</strong> Managed by Fonttech.
          </li>
          <li><strong>Electricity Bill:</strong> Managed by Fonttech.</li>
          <li>
            <strong>Staff Hiring & Training:</strong> Managed by Fonttech.
          </li>
          <li>
            <strong>Interior Fixtures and Branding Costs:</strong> Borne solely
            by Fonttech and will remain Fonttech’s property.
          </li>
        </ul>
      </section>

      <section>
        <h2><strong>Store Setup and Maintenance</strong></h2>
        <p>
          Store interiors, layout, and branding are done as per the guidelines
          laid down by Fonttech through its appointed designing agency. The
          Franchise is expected to maintain the high quality of the interior
          throughout the operation of the Smart Shop.
        </p>
        <p>
          <strong>Maintenance Responsibility:</strong> Any subsequent
          maintenance cost, handling of damages, and wear-and-tear will be borne
          by the Franchise to conform to the quality standards as defined by
          Fonttech.
        </p>
      </section>

      <section>
        <h2><strong>Benefits</strong></h2>
        <ul class="custom-list">
          <li><strong>No Royalty Fee:</strong> Enjoy zero royalty charges.</li>
          <li>
            <strong>Zero Franchise Charge:</strong> No initial franchise fee
            required.
          </li>
          <li>
            <strong>High Profit Potential:</strong> Earn up to 35% profit.
          </li>
        </ul>
      </section>

      <section>
        <h2><strong> Agreement Terms</strong></h2>
        <p><strong>Agreement Duration:</strong> 5 years.</p>
      </section>
    </div>
  </div>
</div>
