<div class="rgtContent">
  <h2 class="heading-02">Franchise Request</h2>
  <div class="rgt-prag">
    <form
    [formGroup]="manualEntryForm"
    (ngSubmit)="onSubmit()"
    class=""
  >
  <div class="row">
    <div class="col-sm-12 col-md-6 col-lg-6">
      <div class="form-group">
        <label class="d-block"
          for="full_name"
          >Full Name <span class="text-red-500">*</span></label
        >
        <input
          inputSanitization
          [supportedCharaterRange]="'a-z A-Z0-9'"
          matInput
          type="text"
          id="name"
          class="form-control"
          placeholder="John Doe"
          formControlName="fullName"
        />
      </div>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-6">
      <div class="form-group">
        <label class="d-block"
          for="phone"
          >Mobile No <span class="text-red-500">*</span></label
        >
        <input
          matInput
          type="text"
          inputSanitization
          [supportedCharaterRange]="'0-9'"
          id="phone"
          class="form-control"
          placeholder="123456789"
          formControlName="mobileNo"
        />
      </div>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-4">
      <div class="form-group">
        <label class="d-block"
          for="email"
          >Email Id <span class="text-red-500">*</span></label
        >
        <input
          matInput
          type="email"
          id="email"
          class="form-control"
          placeholder="john.doe@company.com"
          formControlName="emailId"
        />
      </div>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-4">
      <div class="form-group">
        <label class="d-block"
          for="state"
          >State <span class="text-red-500">*</span></label
        >
        <select
          matInput
          type="text"
          id="state"
          class="form-control"
          formControlName="stateCode"
        >
          <option></option>
          <option *ngFor="let data of stateArray" [value]="data.state_code">
            {{ data.name }}
          </option>
        </select>
      </div>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-4">
      <div class="form-group">
        <label class="d-block"
          for="state"
          >Pin Code <span class="text-red-500">*</span></label
        >
        <input
          inputSanitization
          [supportedCharaterRange]="'0-9'"
          matInput
          type="text"
          id="pincode"
          class="form-control"
          placeholder="123456"
          formControlName="pincode"
        />
      </div>
    </div>
    <div class="col-sm-12 ">
      <div class="form-group">
        <label class="d-block"
          for="address"
          >Full Address <span class="text-red-500">*</span></label
        >
        <textarea
          id="address"
          inputSanitization
          formControlName="address"
          rows="4"
          class="form-control"
          placeholder="Address"
        ></textarea>
      </div>
    </div>
    <div class="col-sm-12 ">
      <div class="mb-6" class="form-group">
        <label class="d-block"
          for="message"
          >Your Message <span class="text-red-500">*</span></label
        >
        <textarea
          id="message"
          formControlName="message"
          rows="4"
          class="form-control"
          placeholder="Write your thoughts here..."
        ></textarea>
      </div>
    </div>
    <div class="col-sm-12 ">
      <div class="form-group">
        <button
      type="submit"
      class="btn btn-primary"
    >
      Submit
    </button>
      </div>
    </div>
  </div>
    
    
      

     
      
     
    
    

   

    
  </form>
  </div>
</div>







