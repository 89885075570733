<div class="content-bg p-4">
  <section class="mb-12">
    <div class="flex ">
      <div class="text-center md:max-w-xl lg:max-w-3xl">
        <h2 class="mb-12 text-3xl font-bold heading-02 pt-5 ">Contact US</h2>
      </div>
    </div>

    <div class="flex justify-center">
      <div class="text-center md:max-w-xl lg:max-w-3xl">
        <h2 class="mb-12 font-normal leading-8 tracking-tight text-black"> You
          can reach out to us anytime.</h2>
      </div>
    </div>

    <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
      <div class="flex flex-wrap mt-8">
        <div class="w-full shrink-0 grow-0 basis-auto lg:w-full">
          <div class="flex flex-wrap">
            <div class="mb-12 w-full shrink-0 grow-0 basis-auto md:w-1/3 md:px-3 lg:px-6">
              <div class="align-start flex">
                <div class="shrink-0">
                  <div class="inline-block rounded-md bg-primary-100 p-4 text-primary">
                    <img src="../../../assets/images/customercare.svg">
                  </div>
                </div>
                <div class="ml-6 grow">
                  <p class="mb-2 font-bold text-black">Customer Care</p>
                  <div class="flex flex-col">
                    <a href="mailto:customercare@fonttech.in" class=" cursor-pointer text-black">
                      customercare@fonttech.in
                    </a>
                    <a [href]="contactNoLink" class=" cursor-pointer text-black">
                      {{contactNo}}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="mb-12 w-full shrink-0 grow-0 basis-auto md:w-1/3 md:px-3 lg:px-6">
              <div class="align-start flex">
                <div class="shrink-0">
                  <div class="inline-block rounded-md bg-primary-100 p-4 text-primary">
                    <img src="../../../assets/images/franchise.svg">
                  </div>
                </div>
                <div class="ml-6 grow">
                  <p class="mb-2 font-bold text-black">Franchise Enquiry</p>
                  <div class="flex flex-col">
                    <a href="mailto:franchise@fonttech.in" class=" cursor-pointer text-black">
                      franchise@fonttech.in
                    </a>
                    <a [href]="contactNoLink" class=" cursor-pointer text-black">
                      {{contactNo}}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="mb-12 w-full shrink-0 grow-0 basis-auto md:w-1/3 md:px-3 lg:px-6">
              <div class="flex items-start">
                <div class="shrink-0">
                  <div class="inline-block rounded-md bg-primary-100 p-4 text-primary">
                    <img src="../../../assets/images/enquiry.svg">
                  </div>
                </div>
                <div class="ml-6 grow">
                  <p class="mb-2 font-bold text-black">
                    Warranty
                  </p>
                  <div class="flex flex-col">
                    <a href="mailto:warranty@fonttech.in" class=" cursor-pointer text-black">
                      warranty@fonttech.in
                    </a>
                    <a [href]="contactNoLink" class=" cursor-pointer text-black">
                      {{contactNo}}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
      <div class="flex flex-wrap mt-8">
        <div class="w-full shrink-0 grow-0 basis-auto lg:w-full">
          <div class="flex flex-wrap">
            <div class="mb-12 w-full shrink-0 grow-0 basis-auto md:w-1/3 md:px-3 lg:px-6">
              <div class="flex items-start">
                <div class="shrink-0">
                  <div class="inline-block rounded-md bg-primary-100 p-4 text-primary">
                    <img src="../../../assets/images/location.png" height="24" width="24">
                  </div>
                </div>
                <div class="ml-6 grow">
                  <p class="mb-2 font-bold text-black">
                    Registered Office:
                  </p>
                  <div class="flex flex-col">
                    <span class=" text-black">
                      TG TECHNOLOGY & CO.
                    </span>
                    <span class=" text-black">
                      6th Floor, Saltee Plaza,
                    </span>
                    <span class="text-xl text-black">
                      Khudiram Bose Sarani,
                    </span>
                    <span class="text-xl cursor-pointer text-black">
                      Kolkata 700080
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

</div>