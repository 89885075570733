<div class="rgtContent">
  <h2 class="heading-02">Franchise Overview</h2>
  <div class="rgt-prag">
    <!-- <p><strong>Overview :</strong></p> -->
    <p>
      Premium Electronics Store is an extension of Fonttech's ongoing efforts in creating
      more engagement points to improve consumer experience. Fonttech Smart
      Shops serve as an exclusive and franchised one-stop shops offering an
      opportunity to the consumer to experience and choose from a wide range of
      products under a single roof while providing an opportunity to our franchise
      partners to associate with us in our efforts of business expansion.
    </p>
    
    <p>
      These exclusive shops also double as a platform for us to engage with
      consumers, understand their expectations and get valuable feedback which
      enables us to improve our product offerings, service and overall consumer
      experience
    </p>
    <p><strong>Our Offerings :</strong></p>
    <ul class="franchiseList mb-4">
      <li>
        <strong>Smart Tv :</strong> Android LED Tv, Google LED Tv, WebOS LED Tv
      </li>
      <li>
        <strong>Home Theater :</strong> Atoms, Bar, Tower,  Trolly
      </li>
      <li>
        <strong>Fans :</strong> Ceiling Fan, Wall Fan, Stand Fan
      </li>      
    </ul>
  </div>
</div>