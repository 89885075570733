<app-modal [content]="content" [header]="header" (confirmEvent)="onClickRedirect($event)" *ngIf="_modalService.modalState$ | async"></app-modal>
<div class="content-bg min-h-screen">
  <!-- <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel" [attr.data-interval]="carouselInterval" (mouseenter)="pauseCarousel()" (mouseleave)="resumeCarousel()">
    <ol class="carousel-indicators">
      <li *ngFor="let item of imageBannerArr; let i = index" [attr.data-target]="'#carouselExampleIndicators'" [attr.data-slide-to]="i" [class.active]="i === 0"></li>
    </ol>
    <div class="carousel-inner">
      <div *ngFor="let item of imageBannerArr; let i = index" class="carousel-item" [class.active]="i === 0">
        <img class="d-block w-100" [src]="item.imageUrl" [alt]="'Slide ' + (i + 1)" (click)="handleCarouselItemClick(item)">
        <div class="carousel-caption d-none d-md-block">
          <h5>{{ item.title }}</h5>
          <p>{{ item.description }}</p>
        </div>
      </div>
    </div>
  </div> -->
  
  <div id="carouselExampleAutoplaying" class="carousel slide" data-bs-ride="carousel" [attr.data-bs-pause]="hoverPause">
    <div class="carousel-indicators">
        <button *ngFor="let item of imageBannerArr; let i = index" type="button" [attr.data-bs-target]="'#carouselExampleAutoplaying'" [attr.data-bs-slide-to]="i" [class]="item.lineClass" [attr.aria-current]="i === 0 ? 'true' : 'false'" aria-label="'Slide '+(i+1)"></button>
    </div>
    <div class="carousel-inner">
        <div *ngFor="let item of imageBannerArr; let i = index;" [class]="item.class" [attr.data-bs-interval]="carouselInterval">
            <img class="d-block w-100" [src]="item.imageUrl" [alt]="'Slide '+(i + 1)" (click)="handleCarouselItemClick(item)">
            <div class="carousel-caption d-none d-md-block">
                <h5>{{ item.title }}</h5>
                <p>{{ item.description }}</p>
            </div>
        </div>
    </div>
</div>
<section class="white-bg pr pt-15 pb-15">
  <div class="container pr-z2">
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-6">
        <div class="flex flex-col">
          <h1 class="heading-02 mb-5 pt-5">
            About <small>FontTech</small>
          </h1>
          <div class="about-company">
            <p class="mb-4">
              <span class="text-blue">FontTech</span>, a leading player in consumer electronics, offers a wide range of
              top-quality products like  <span class="text-blue">TV </span>,<span class="text-blue">Home Theater</span>, <span class="text-blue"> Fan</span>. With a commitment
              to innovation and excellence, FontTech has expanded its footprint with
              over 100 shops across India, providing customers nationwide with
              cutting-edge electronic solutions.
            </p>
            <p class="mb-4">
              As part of their growth strategy, FontTech welcomes entrepreneurs through
              franchise opportunities, fostering a network of partners who share the
              brand's dedication to quality. Their products, known for advanced features
              and reliability, make FontTech a preferred choice for consumers seeking a
              superior electronic experience.
            </p>
          </div>          
        </div>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-6">
        <figure class="mt-5 pt-5 mb-0 d-flex justify-content-end">
          <img src="../../../assets/images/hero_img.png" class="img-fluid">
        </figure>
      </div>
    </div>
  </div>
  <!--Effect Start-->
  
<!-- Sphere-->
<div class="main-wrapper">
  <div class="sphere-wrapper">
    <div class="plane plane-1">
      <div class="spoke spoke-1">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-2">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-3">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-4">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-5">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-6">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-7">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-8">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-9">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-10">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-11">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-12">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-13">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-14">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-15">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-16">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-17">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-18">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-19">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-20">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-21">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-22">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-23">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-24">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-25">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-26">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-27">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-28">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-29">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-30">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-31">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-32">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-33">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-34">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-35">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-36">
        <div class="dot"></div>
      </div>
    </div>
    <div class="plane plane-2">
      <div class="spoke spoke-1">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-2">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-3">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-4">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-5">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-6">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-7">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-8">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-9">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-10">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-11">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-12">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-13">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-14">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-15">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-16">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-17">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-18">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-19">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-20">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-21">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-22">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-23">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-24">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-25">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-26">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-27">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-28">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-29">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-30">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-31">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-32">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-33">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-34">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-35">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-36">
        <div class="dot"></div>
      </div>
    </div>
    <div class="plane plane-3">
      <div class="spoke spoke-1">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-2">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-3">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-4">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-5">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-6">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-7">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-8">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-9">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-10">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-11">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-12">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-13">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-14">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-15">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-16">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-17">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-18">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-19">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-20">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-21">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-22">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-23">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-24">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-25">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-26">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-27">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-28">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-29">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-30">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-31">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-32">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-33">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-34">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-35">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-36">
        <div class="dot"></div>
      </div>
    </div>
    <div class="plane plane-4">
      <div class="spoke spoke-1">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-2">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-3">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-4">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-5">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-6">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-7">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-8">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-9">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-10">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-11">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-12">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-13">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-14">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-15">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-16">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-17">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-18">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-19">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-20">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-21">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-22">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-23">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-24">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-25">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-26">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-27">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-28">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-29">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-30">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-31">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-32">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-33">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-34">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-35">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-36">
        <div class="dot"></div>
      </div>
    </div>
    <div class="plane plane-5">
      <div class="spoke spoke-1">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-2">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-3">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-4">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-5">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-6">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-7">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-8">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-9">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-10">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-11">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-12">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-13">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-14">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-15">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-16">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-17">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-18">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-19">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-20">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-21">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-22">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-23">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-24">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-25">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-26">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-27">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-28">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-29">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-30">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-31">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-32">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-33">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-34">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-35">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-36">
        <div class="dot"></div>
      </div>
    </div>
    <div class="plane plane-6">
      <div class="spoke spoke-1">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-2">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-3">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-4">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-5">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-6">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-7">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-8">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-9">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-10">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-11">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-12">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-13">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-14">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-15">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-16">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-17">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-18">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-19">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-20">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-21">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-22">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-23">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-24">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-25">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-26">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-27">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-28">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-29">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-30">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-31">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-32">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-33">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-34">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-35">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-36">
        <div class="dot"></div>
      </div>
    </div>
    <div class="plane plane-7">
      <div class="spoke spoke-1">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-2">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-3">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-4">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-5">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-6">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-7">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-8">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-9">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-10">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-11">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-12">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-13">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-14">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-15">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-16">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-17">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-18">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-19">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-20">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-21">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-22">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-23">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-24">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-25">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-26">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-27">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-28">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-29">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-30">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-31">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-32">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-33">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-34">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-35">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-36">
        <div class="dot"></div>
      </div>
    </div>
    <div class="plane plane-8">
      <div class="spoke spoke-1">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-2">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-3">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-4">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-5">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-6">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-7">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-8">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-9">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-10">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-11">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-12">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-13">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-14">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-15">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-16">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-17">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-18">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-19">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-20">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-21">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-22">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-23">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-24">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-25">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-26">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-27">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-28">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-29">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-30">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-31">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-32">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-33">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-34">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-35">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-36">
        <div class="dot"></div>
      </div>
    </div>
    <div class="plane plane-9">
      <div class="spoke spoke-1">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-2">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-3">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-4">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-5">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-6">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-7">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-8">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-9">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-10">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-11">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-12">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-13">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-14">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-15">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-16">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-17">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-18">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-19">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-20">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-21">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-22">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-23">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-24">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-25">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-26">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-27">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-28">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-29">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-30">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-31">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-32">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-33">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-34">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-35">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-36">
        <div class="dot"></div>
      </div>
    </div>
    <div class="plane plane-10">
      <div class="spoke spoke-1">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-2">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-3">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-4">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-5">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-6">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-7">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-8">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-9">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-10">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-11">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-12">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-13">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-14">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-15">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-16">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-17">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-18">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-19">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-20">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-21">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-22">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-23">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-24">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-25">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-26">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-27">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-28">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-29">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-30">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-31">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-32">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-33">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-34">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-35">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-36">
        <div class="dot"></div>
      </div>
    </div>
    <div class="plane plane-11">
      <div class="spoke spoke-1">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-2">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-3">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-4">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-5">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-6">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-7">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-8">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-9">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-10">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-11">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-12">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-13">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-14">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-15">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-16">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-17">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-18">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-19">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-20">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-21">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-22">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-23">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-24">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-25">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-26">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-27">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-28">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-29">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-30">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-31">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-32">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-33">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-34">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-35">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-36">
        <div class="dot"></div>
      </div>
    </div>
    <div class="plane plane-12">
      <div class="spoke spoke-1">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-2">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-3">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-4">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-5">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-6">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-7">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-8">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-9">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-10">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-11">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-12">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-13">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-14">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-15">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-16">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-17">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-18">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-19">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-20">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-21">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-22">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-23">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-24">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-25">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-26">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-27">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-28">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-29">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-30">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-31">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-32">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-33">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-34">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-35">
        <div class="dot"></div>
      </div>
      <div class="spoke spoke-36">
        <div class="dot"></div>
      </div>
    </div>
  </div>
</div>
  <!--Effect End-->
</section>

<section class="expertise patternHeading heroAnimation content-text inner-gradin pb-8">
  <div class="expertise-top"><img src="../../../assets/images/Expertise-top.webp"></div>
  <div class="container pr-z2" >
    <div class="main-wrapper lft-circle">
      <div class="sphere-wrapper">
        <div class="plane plane-1">
          <div class="spoke spoke-1">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-2">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-3">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-4">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-5">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-6">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-7">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-8">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-9">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-10">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-11">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-12">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-13">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-14">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-15">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-16">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-17">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-18">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-19">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-20">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-21">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-22">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-23">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-24">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-25">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-26">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-27">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-28">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-29">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-30">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-31">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-32">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-33">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-34">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-35">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-36">
            <div class="dot"></div>
          </div>
        </div>
        <div class="plane plane-2">
          <div class="spoke spoke-1">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-2">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-3">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-4">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-5">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-6">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-7">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-8">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-9">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-10">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-11">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-12">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-13">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-14">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-15">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-16">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-17">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-18">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-19">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-20">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-21">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-22">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-23">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-24">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-25">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-26">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-27">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-28">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-29">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-30">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-31">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-32">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-33">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-34">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-35">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-36">
            <div class="dot"></div>
          </div>
        </div>
        <div class="plane plane-3">
          <div class="spoke spoke-1">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-2">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-3">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-4">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-5">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-6">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-7">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-8">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-9">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-10">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-11">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-12">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-13">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-14">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-15">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-16">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-17">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-18">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-19">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-20">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-21">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-22">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-23">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-24">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-25">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-26">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-27">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-28">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-29">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-30">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-31">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-32">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-33">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-34">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-35">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-36">
            <div class="dot"></div>
          </div>
        </div>
        <div class="plane plane-4">
          <div class="spoke spoke-1">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-2">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-3">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-4">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-5">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-6">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-7">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-8">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-9">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-10">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-11">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-12">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-13">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-14">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-15">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-16">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-17">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-18">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-19">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-20">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-21">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-22">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-23">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-24">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-25">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-26">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-27">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-28">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-29">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-30">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-31">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-32">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-33">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-34">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-35">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-36">
            <div class="dot"></div>
          </div>
        </div>
        <div class="plane plane-5">
          <div class="spoke spoke-1">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-2">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-3">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-4">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-5">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-6">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-7">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-8">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-9">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-10">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-11">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-12">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-13">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-14">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-15">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-16">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-17">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-18">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-19">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-20">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-21">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-22">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-23">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-24">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-25">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-26">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-27">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-28">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-29">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-30">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-31">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-32">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-33">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-34">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-35">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-36">
            <div class="dot"></div>
          </div>
        </div>
        <div class="plane plane-6">
          <div class="spoke spoke-1">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-2">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-3">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-4">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-5">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-6">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-7">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-8">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-9">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-10">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-11">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-12">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-13">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-14">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-15">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-16">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-17">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-18">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-19">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-20">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-21">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-22">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-23">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-24">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-25">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-26">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-27">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-28">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-29">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-30">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-31">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-32">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-33">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-34">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-35">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-36">
            <div class="dot"></div>
          </div>
        </div>
        <div class="plane plane-7">
          <div class="spoke spoke-1">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-2">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-3">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-4">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-5">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-6">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-7">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-8">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-9">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-10">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-11">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-12">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-13">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-14">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-15">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-16">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-17">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-18">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-19">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-20">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-21">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-22">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-23">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-24">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-25">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-26">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-27">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-28">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-29">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-30">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-31">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-32">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-33">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-34">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-35">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-36">
            <div class="dot"></div>
          </div>
        </div>
        <div class="plane plane-8">
          <div class="spoke spoke-1">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-2">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-3">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-4">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-5">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-6">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-7">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-8">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-9">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-10">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-11">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-12">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-13">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-14">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-15">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-16">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-17">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-18">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-19">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-20">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-21">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-22">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-23">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-24">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-25">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-26">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-27">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-28">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-29">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-30">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-31">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-32">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-33">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-34">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-35">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-36">
            <div class="dot"></div>
          </div>
        </div>
        <div class="plane plane-9">
          <div class="spoke spoke-1">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-2">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-3">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-4">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-5">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-6">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-7">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-8">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-9">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-10">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-11">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-12">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-13">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-14">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-15">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-16">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-17">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-18">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-19">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-20">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-21">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-22">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-23">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-24">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-25">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-26">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-27">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-28">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-29">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-30">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-31">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-32">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-33">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-34">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-35">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-36">
            <div class="dot"></div>
          </div>
        </div>
        <div class="plane plane-10">
          <div class="spoke spoke-1">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-2">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-3">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-4">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-5">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-6">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-7">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-8">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-9">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-10">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-11">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-12">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-13">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-14">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-15">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-16">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-17">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-18">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-19">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-20">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-21">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-22">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-23">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-24">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-25">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-26">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-27">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-28">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-29">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-30">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-31">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-32">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-33">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-34">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-35">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-36">
            <div class="dot"></div>
          </div>
        </div>
        <div class="plane plane-11">
          <div class="spoke spoke-1">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-2">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-3">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-4">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-5">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-6">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-7">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-8">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-9">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-10">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-11">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-12">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-13">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-14">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-15">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-16">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-17">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-18">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-19">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-20">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-21">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-22">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-23">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-24">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-25">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-26">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-27">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-28">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-29">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-30">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-31">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-32">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-33">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-34">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-35">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-36">
            <div class="dot"></div>
          </div>
        </div>
        <div class="plane plane-12">
          <div class="spoke spoke-1">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-2">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-3">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-4">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-5">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-6">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-7">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-8">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-9">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-10">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-11">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-12">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-13">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-14">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-15">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-16">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-17">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-18">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-19">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-20">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-21">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-22">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-23">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-24">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-25">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-26">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-27">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-28">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-29">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-30">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-31">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-32">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-33">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-34">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-35">
            <div class="dot"></div>
          </div>
          <div class="spoke spoke-36">
            <div class="dot"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="flex flex-col mx-auto lg:py-4">
        <h1 class="heading-02 mb-5 text-black text-center mb-5 pb-5">
          Our <small>Products</small>
        </h1>
      </div>
      <div class="product-sec">
        <div class="product border-animation" *ngFor="let item of productsArr">
          <div class="product-box">
            <figure class="">
              <img class="h-40 md:h-44 lg:h-48" [src]="item.src" alt="{{ item.name }}"
              />
            </figure>
            <h4 class="product-name text-black">{{ item.name }}</h4>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--Product Effect Start-->
  <div class="hero">
    <div class="snow">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1536" preserveAspectRatio="xMidYMax slice">
		    <g fill="#FFF" fill-opacity=".15" transform="translate(55 42)">
			   <g id="snow-bottom-layer">
				  <ellipse cx="6" cy="1009.5" rx="6" ry="5.5"/>
				  <ellipse cx="138" cy="1110.5" rx="6" ry="5.5"/>
				  <ellipse cx="398" cy="1055.5" rx="6" ry="5.5"/>
				  <ellipse cx="719" cy="1284.5" rx="6" ry="5.5"/>
				  <ellipse cx="760" cy="1155.5" rx="6" ry="5.5"/>
				  <ellipse cx="635" cy="1459.5" rx="6" ry="5.5"/>
				  <ellipse cx="478" cy="1335.5" rx="6" ry="5.5"/>
				  <ellipse cx="322" cy="1414.5" rx="6" ry="5.5"/>
				  <ellipse cx="247" cy="1234.5" rx="6" ry="5.5"/>
				  <ellipse cx="154" cy="1425.5" rx="6" ry="5.5"/>
				  <ellipse cx="731" cy="773.5" rx="6" ry="5.5"/>
				  <ellipse cx="599" cy="874.5" rx="6" ry="5.5"/>
				  <ellipse cx="339" cy="819.5" rx="6" ry="5.5"/>
				  <ellipse cx="239" cy="1004.5" rx="6" ry="5.5"/>
				  <ellipse cx="113" cy="863.5" rx="6" ry="5.5"/>
				  <ellipse cx="102" cy="1223.5" rx="6" ry="5.5"/>
				  <ellipse cx="395" cy="1155.5" rx="6" ry="5.5"/>
				  <ellipse cx="826" cy="943.5" rx="6" ry="5.5"/>
				  <ellipse cx="626" cy="1054.5" rx="6" ry="5.5"/>
				  <ellipse cx="887" cy="1366.5" rx="6" ry="5.5"/>
				  <ellipse cx="6" cy="241.5" rx="6" ry="5.5"/>
				  <ellipse cx="138" cy="342.5" rx="6" ry="5.5"/>
				  <ellipse cx="398" cy="287.5" rx="6" ry="5.5"/>
				  <ellipse cx="719" cy="516.5" rx="6" ry="5.5"/>
				  <ellipse cx="760" cy="387.5" rx="6" ry="5.5"/>
				  <ellipse cx="635" cy="691.5" rx="6" ry="5.5"/>
				  <ellipse cx="478" cy="567.5" rx="6" ry="5.5"/>
				  <ellipse cx="322" cy="646.5" rx="6" ry="5.5"/>
				  <ellipse cx="247" cy="466.5" rx="6" ry="5.5"/>
				  <ellipse cx="154" cy="657.5" rx="6" ry="5.5"/>
				  <ellipse cx="731" cy="5.5" rx="6" ry="5.5"/>
				  <ellipse cx="599" cy="106.5" rx="6" ry="5.5"/>
				  <ellipse cx="339" cy="51.5" rx="6" ry="5.5"/>
				  <ellipse cx="239" cy="236.5" rx="6" ry="5.5"/>
				  <ellipse cx="113" cy="95.5" rx="6" ry="5.5"/>
				  <ellipse cx="102" cy="455.5" rx="6" ry="5.5"/>
				  <ellipse cx="395" cy="387.5" rx="6" ry="5.5"/>
				  <ellipse cx="826" cy="175.5" rx="6" ry="5.5"/>
				  <ellipse cx="626" cy="286.5" rx="6" ry="5.5"/>
				  <ellipse cx="887" cy="598.5" rx="6" ry="5.5"/>
			   </g>
		    </g>
		    <g fill="#FFF" fill-opacity=".3" transform="translate(65 63)">
			   <g id="snow-top-layer">
				  <circle cx="8" cy="776" r="8"/>
				  <circle cx="189" cy="925" r="8"/>
				  <circle cx="548" cy="844" r="8"/>
				  <circle cx="685" cy="1115" r="8"/>
				  <circle cx="858" cy="909" r="8"/>
				  <circle cx="874" cy="1438" r="8" transform="rotate(180 874 1438)"/>
				  <circle cx="657" cy="1256" r="8" transform="rotate(180 657 1256)"/>
				  <circle cx="443" cy="1372" r="8" transform="rotate(180 443 1372)"/>
				  <circle cx="339" cy="1107" r="8" transform="rotate(180 339 1107)"/>
				  <circle cx="24" cy="1305" r="8" transform="rotate(180 24 1305)"/>
				  <circle cx="8" cy="8" r="8"/>
				  <circle cx="189" cy="157" r="8"/>
				  <circle cx="548" cy="76" r="8"/>
				  <circle cx="685" cy="347" r="8"/>
				  <circle cx="858" cy="141" r="8"/>
				  <circle cx="874" cy="670" r="8" transform="rotate(180 874 670)"/>
				  <circle cx="657" cy="488" r="8" transform="rotate(180 657 488)"/>
				  <circle cx="443" cy="604" r="8" transform="rotate(180 443 604)"/>
				  <circle cx="339" cy="339" r="8" transform="rotate(180 339 339)"/>
				  <circle cx="24" cy="537" r="8" transform="rotate(180 24 537)"/>
			   </g>
		    </g>
		</svg>
    </div>
</div>
  <!--Product Effect End-->
  
</section>
</div>

<!-- <div class="get-inTouch">
  <button>get in touch</button>
</div> -->